import {
  addDoc,
  collection,
  getDocs,
} from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { db } from "../../config/firebase";
import { Link, useNavigate } from "react-router-dom";


import Spinner from "../Spinner";
import JoditEditor from "jodit-react";

const InsertGoogleQuestions = () => {
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const editor = useRef(null);
  const [description, setDescription] = useState("");

  const navigate = useNavigate();

  const googleProblems = collection(db, "GoogleProblems");

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      setLoading(true);

      await addDoc(googleProblems, {
        title: title.trim(),
        description: description,
      });

      setLoading(false);
      clearData();

      getData();
    } catch (err) {
      console.log(err);
    }
  };

  const getData = async () => {
    const data = await getDocs(googleProblems);
    const filteredData = data.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    setPosts(filteredData);
  };

  const clearData = () => {
    setTitle("");
    setDescription("");
  };

  useEffect(() => {
    const auth = localStorage.getItem("auth");
    if (auth !== "Logged In") {
      navigate("/admin/login");
    }
    getData();
  }, []);

  return (
    <div>
      <div className="flex-col justify-between w-full bg-white mx-auto shadow-sm  py-4">
        <div className="flex justify-between px-4">
          <div className="font-semibold text-2xl text-center w-full">
            Add Google Question
          </div>
        </div>
        <div className=" bg-white m-20 p-7 rounded-md">
          {loading && (
            <div className=" flex items-center justify-center mt-40">
              <Spinner />
            </div>
          )}
          <div className="flex-col justify-center">
            <div className="text-xl font-semibold">Add Problem</div>
            <div className="text-gray-600">
              Easily input essential details like google leetcode problem
            </div>

            <div className="flex justify-between gap-x-14">
              <div className="w-full mx-auto">
                <div className="mt-4 text-lg py-1">
                  Problem <sup className="text-red-500 text-lg">*</sup>
                </div>
                <input
                  className="border-2 rounded-md py-3 w-full px-2 text-lg"
                  required
                  name="name"
                  placeholder="Enter title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
            </div>

            <div className="flex justify-between gap-x-14">
              <div className="w-full mx-auto"></div>
            </div>

            <div className="py-6">
              <div className="">
                <JoditEditor
                  ref={editor}
                  value={description}
                  onChange={(newContent) => setDescription(newContent)}
                />
              </div>
            </div>

            <div className="text-center md:text-left py-4">
              <button
                onClick={handleSubmit}
                className="bg-[#146eb4] text-white h-10 w-[100px] font-semibold rounded-md text-richblack-700 hover:scale-110 transition duration-300 ease-in cursor-pointer "
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className=" w-full mx-auto py-2">
        <div className="overflow-hidden">
          <table className="w-6/12 mx-auto overflow-y-scroll text-left text-sm font-light">
            <thead className="font-medium ">
              <tr className="bg-orange-300 border-2 border-black text-blue-800 font-bold text-md md:text-xl ">
                <th scope="col" className="px-1 py-2 border-r-2 text-center">
                  #
                </th>
                <th
                  scope="col"
                  className="px-1 py-2 border-r-2  border-black text-center"
                >
                  Title
                </th>
              </tr>
            </thead>

            <tbody>
              {posts.map((p, index) => (
                <tr className="border-2 border-black ">
                  <td className="whitespace-wrap text-[12px] font-medium px-1 py-2 border-r-2  align-baseline text-center text-wrap">
                    {index + 1}
                  </td>
                  <td className="whitespace-wrap text-md font-medium px-1 py-2 border-black  border-r-2 align-baseline text-center text-wrap">
                    
                  <Link className='text-blue-600 underline' to={`/admin/googlequestionspage?title=${p.title}`}> {p.title}</Link>
                   
                  </td>
                 
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default InsertGoogleQuestions;
