import {
    addDoc,
    collection,
    getDocs,
  } from "firebase/firestore";
  import React, { useEffect, useRef, useState } from "react";
  import { db } from "../../config/firebase";
  import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";

const GoogleQuestionsPage = () => {
    const [posts, setPosts] = useState([]);

    const googleProblems = collection(db, "GoogleProblems");
   
    const [searchParams, setSearchParams] = useSearchParams();
    const title = searchParams.get("title");


    const getData =  async() => {
        const data = await getDocs(googleProblems);
        const filteredData = data.docs.map((doc) => ({...doc.data(), id:doc.id}));
       
        const uploaded = filteredData.filter(x => x.title === title);
        
    
        setPosts(uploaded[0]);

      }

      useEffect(() => {
        getData();
      }, [])

  return (
    <div className="w-8/10 mx-auto">
    <div className="text-center text-blue-800 font-semibold text-xl p-5">{title}</div>
     <Link to={'/admin/googlequestions'} className="text-blue-600 p-2 font-bold underline">Back</Link>
        <div className="text-richblack-700 font-normal text-[17px] text-left m-6 leading-9">   
        {
            posts && <span dangerouslySetInnerHTML={{__html: posts.description}} />
        }     
        
                </div>
    </div>
  )
}

export default GoogleQuestionsPage